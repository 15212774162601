import { css } from '@emotion/css';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
    fetchRetailElectricProviders,
    newAgreement
} from '../../../api/api';
import type { Provider, SmtGetInfoProps } from '../../../types';
import { CheckboxItem } from '../../checkbox-item';
import { FormLoading } from '../../form-loading';
import { Button } from '../../styles/MyComponents.styled';

export const SmtGetInfo: React.FC<SmtGetInfoProps> = ({ handleShowAgreement, handleBack, hideBack }) => {
    const { t } = useTranslation('usage');
    const location = useLocation();
    const [providers, setProviders] = useState<Provider[]>([]);
    const [loadingProviders, setLoadingProviders] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState<Provider | undefined>(undefined);
    const [showProviderError, setShowProviderError] = useState(false);
    const [showConsentError, setShowConsentError] = useState(false);
    const [meterNumber, setMeterNumber] = useState('');
    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [forwardEmail, setForwardEmail] = useState(false);

    const navigate = useNavigate()
    const { address, esiid, zipcode, city, state, utcode } = qs.parse(location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        setLoadingProviders(true)
        fetchRetailElectricProviders().then(res => {
            if (res) setProviders(res)
            setLoadingProviders(false)
        })
    }, [])

    const handleSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();
        evt.stopPropagation();

        const esiid = qs.parse(location.search, { ignoreQueryPrefix: true }).esiid;
        const form = evt.currentTarget as HTMLInputElement;
        const validProvider = handleValidProvider()
        setValidated(true);
        if (!isAgree) {
            setShowConsentError(true);
            return;
        }
        if (!form.checkValidity() || !validProvider || !selectedProvider || !esiid || submitting) return;

        setSubmitting(true);
        newAgreement(
            esiid,
            meterNumber,
            selectedProvider.certificate_number,
            email,
            forwardEmail ? '1' : '0'
        ).then(res => {
            if (res && res.response && res.response.consent_id) {
                handleShowAgreement(res.response.consent_id, email);
            }
            else {
                if (res.error_code && res.error_code === '500') {
                    toast.error(`${t('Incorrect ESIID-METER-REP Combination')}`);
                }
                setSubmitting(false);
                console.error('error: new agreement');
            }
        })
    }

    const handleValidProvider = () => {
        let isValid = true
        setShowProviderError(false)
        if (!selectedProvider) {
            isValid = false
            setShowProviderError(true)
        }
        return isValid
    }

    const selectStyles = {
        control: (style: object) => ({
            ...style,
            borderColor: showProviderError ? '#dc3545 !important' : '#ced4da',
            borderRadius: '.3rem'
        }),
        singleValue: (style: object) => ({
            ...style,
            color: '#495057',
            fontSize: '1.25rem',
            lineHeight: 1.5
        }),
        valueContainer: (style: object) => ({
            ...style,
            padding: '7px 1rem'
        })
    }

    return (
        <div className="position-relative">
            <h2>{t('We are almost there!')}</h2>
            <p>{t('To ensure the accuracy of the data, we will need your Smart Meter Texas (SMT) number and the permission to access the data')}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>{t('Provider')}</Form.Label>
                    <Select
                        instanceId="repsSelect"
                        value={selectedProvider}
                        onChange={(obj: any) => {
                            setSelectedProvider(obj)
                            setShowProviderError(false)
                        }}
                        options={providers}
                        getOptionLabel={option => `${option.business_name}`}
                        getOptionValue={option => `${option.business_name}`}
                        isSearchable={true}
                        maxMenuHeight={200}
                        styles={selectStyles}
                        isLoading={loadingProviders}
                        isDisabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Meter Number')}</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        onChange={(evt) => {
                            evt.target.value.length < 20 && setMeterNumber(evt.target.value)
                        }}
                        value={meterNumber}
                        disabled={submitting}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('Email')}</Form.Label>
                    <Form.Control
                        required
                        size="lg"
                        type="email"
                        onChange={evt => setEmail(evt.target.value)}
                        value={email}
                        disabled={submitting}
                    />
                </Form.Group>
                <div className="checkbox-row">
                    <CheckboxItem
                        label={t("Would you like to receive a copy of the consent?")}
                        value="1"
                        key="0"
                        checked={forwardEmail}
                        handleClick={() => {
                            setForwardEmail(!forwardEmail)
                        }}
                        disabled={submitting}
                    />
                </div>
                <div className="checkbox-row">
                    <CheckboxItem
                        label={t("By clicking on the box, I agree to grant FreeEnergy permission to access my account on Smart Meter Texas (SMT). In addition, FreeEnergy shall provide all SMT email communications received by FreeEnergy on your behalf to your email address provided.")}
                        value="1"
                        key="0"
                        checked={isAgree}
                        handleClick={() => {
                            setShowConsentError(false);
                            setIsAgree(!isAgree)
                        }}
                        disabled={submitting}
                        error={showConsentError}
                    />
                </div>
                <Form.Group className="mb-0 d-flex justify-content-between mt-5">
                    {
                        !hideBack &&
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={() => { handleBack() }}
                            style={{ minWidth: 130 }}
                        >{t('Back')}</Button>
                    }
                    <Button
                        type="submit"
                        disabled={submitting}
                        data-test-id="cta"
                        style={{ minWidth: 130 }}
                    >{t('Continue')}</Button>
                </Form.Group>
            </Form>
            <FormLoading show={submitting} />

            {location.pathname === '/get_usage' && <p
                onClick={() => {
                    if (typeof address === 'string')
                        navigate(`/plans?address=${encodeURIComponent(address)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utcode}`);
                }}
                className={css`
                    text-decoration: underline;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 1.3rem;
                    color: #e35760;
                    width: fit-content;
                    font-weight: 600;
                    position: absolute;
                    top: 0;
                    right: 0;
                `}><img src='/images/icon_arrow_right.png' alt='' /> skip</p>}
        </div>
    )
}
