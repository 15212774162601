import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { EnrollmentStepWrapper } from '../styles/Enrollment.styled'
import { Button } from '../styles/MyComponents.styled'
import { PlanProps } from '../../types'
import { useEffect, useState } from 'react'
interface Props {
    show: boolean
    result: any
    sign: string
    data?: PlanProps
}

export const EnrollmentStepConfirmation: React.FC<Props> = ({ result, show, sign, data }) => {
    const [ provider, setProvider ] = useState('');

    useEffect(() => {
        if (data && data.source) {
            setProvider(data.source);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showResult = () => {
        if (result) {
            const { additional, message, status } = result
            const errorCode = status.split('.')[0]
            switch (errorCode) {
                case '10':
                    return <Successful provider={provider} />
                case '20':
                    return <PendingDeposit additional={additional} provider={provider} />
                case '30':
                    return <PendingVerification message={message} additional={additional} provider={provider} />
                case '40':
                    return <ContactLater provider={provider} />
                case '50':
                    return <Rejected provider={provider} />
                default:
                    return null
            }
        }
        return null
    }

    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            <div className="p-4">{showResult()}</div>
        </EnrollmentStepWrapper>
    )
}

const Successful = ({ provider }: { provider: string; }) => {
    const { t } = useTranslation('enrollment')
    const navigate = useNavigate()
    return (
        <div>
            <div className="text-center">
                <h1>{t('Congratulations')}!</h1>
                <p>
                    {t(
                        'Your application has been approved! Please wait for the email confirmation from the Retail Energy Provider (REP) including the plan details and the next steps. Thank you for choosing FreeEnergy.'
                    )}
                </p>
                {
                    provider && provider === 'constellation' &&
                    <p>{t('Dedicated Toll-Free')}: 1-855-796-0871</p>
                }
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            navigate('/')
                        }}>
                        {t('Close the Window')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const Rejected = ({ provider }: { provider: string; }) => {
    const { t } = useTranslation('enrollment')
    const navigate = useNavigate()
    return (
        <div>
            <div className="text-center">
                <h1>{t('Your application was not approved')}</h1>
                <Trans i18nKey="enrollment:RejectionDesc">
                    Unfortunately your application is not approved by the selected Retail Energy
                    Provider (REP) at this time. Please try another REP or <a href="#" target="_blank" rel="noreferrer">contact us</a> for more prepaid plans.
                </Trans>
                {
                    provider && provider === 'constellation' &&
                    <p>{t('Dedicated Toll-Free')}: 1-855-796-0871</p>
                }
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            navigate('/')
                        }}>
                        {t('Close the Window')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const PendingDeposit = ({ additional, provider }: {
    additional: any;
    provider: string;
}) => {
    const { t } = useTranslation('enrollment')

    const getAdditionalContent = () => {
        return additional.map((val: any, idx: number) => {
            return (
                <div className="mb-2">
                    <Button
                        key={idx}
                        onClick={() => {
                            if (val.value.indexOf('http') === 0) {
                                if (typeof window !== undefined) {
                                    window.open(val.value, '_blank')
                                }
                            } else {
                                document.location.href = `tel:${val.value}`
                            }
                        }}>
                        {t(val.label)}
                    </Button>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="text-center">
                <h1>{t('We are almost there!')}</h1>
                <p>
                    {t(
                        'Thank you for your patience. In order to complete your enrollment, please click the button below to pay your deposit via credit card.'
                    )}
                </p>
                {
                    provider && provider === 'constellation' &&
                    <p>{t('Dedicated Toll-Free')}: 1-855-796-0871</p>
                }
                <div className="mt-5">{getAdditionalContent()}</div>
            </div>
            <div className="mt-5">
                <h5>{t('Why should I pay the deposit?')}</h5>
                <p>
                    {t(
                        'Deposit is required by the Retail Energy Provider after a careful review of your credit history. Your payment information will be sent to the Retail Energy Provider to process your deposit and will not be stored, used or shared by FreeEnergy in any means.'
                    )}
                </p>
            </div>
        </div>
    )
}

const PendingVerification = ({ message, additional, provider }: {
    message: string;
    additional: any;
    provider: string;
}) => {
    const { t } = useTranslation('enrollment')
    const navigate = useNavigate()

    const getAdditionalContent = () => {
        return additional.map((val: any, idx: number) => {
            return (
                <div className="mb-2">
                    <Button
                        key={idx}
                        onClick={() => {
                            if (val.link.indexOf('http') === 0) {
                                if (typeof window !== undefined) {
                                    window.open(val.link, '_blank')
                                }
                            } else {
                                document.location.href = `tel:${val.link}`
                            }
                        }}>
                        {t(val.label)}
                    </Button>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="text-center">
                <h1>{t('Pending verification by the Retail Energy Provider')}</h1>
                <p>
                    {t(
                        'The Retail Energy Provider (REP) is verifying some of your information and it might take a little longer. We will send an email to inform you of the decision once we hear back from the REP.  Hold tight and we are almost there!'
                    )}
                </p>
                <p>{t(message)}</p>
                {
                    provider && provider === 'constellation' &&
                    <p>{t('Dedicated Toll-Free')}: 1-855-796-0871</p>
                }
                <div className="mt-5">
                    {getAdditionalContent()}
                    <Button
                        onClick={() => {
                            navigate('/')
                        }}>
                        {t('Close the Window')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const ContactLater = ({ provider }: { provider: string; }) => {
    const { t } = useTranslation('enrollment')
    const navigate = useNavigate()
    return (
        <div>
            <div className="text-center">
                <h1>{t('Pending Review')}</h1>
                <p>
                    {t(
                        'Your application was received. The decision will be sent to your email address shortly. Thank you for choosing FreeEnergy.'
                    )}
                </p>
                {
                    provider && provider === 'constellation' &&
                    <p>{t('Dedicated Toll-Free')}: 1-855-796-0871</p>
                }
                <div className="mt-5">
                    <Button
                        onClick={() => {
                            navigate('/')
                        }}>
                        {t('Close the Window')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
