import styled from 'styled-components';


export const FooterWapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const StyledFooter = styled.div`
    padding-top: 3rem;
    padding-bottom: 24px;
    margin-top:auto;
    @media (max-width:990px){
        margin-top:1rem;
    }
`

export const Copyright = styled.div`
    font-size: 13px;
    opacity: .5;
    text-align: center;
    font-weight: 300;
    margin-top: 44px;

    a {
        color: #000;
    }
`

export const FooterNavRow = styled.div`
    display: flex;
    padding:0 10px;
    gap:160px;

    @media (max-width:990px){
        flex-direction:column-reverse;
        gap:20px;
    }
`

export const FooterNavCol = styled.div`
    flex: 1;

    & > div{
        padding: 4px 0;
    }

    h6{
        margin-bottom: 16px;
    }

    a{
        color: #000;
        text-decoration: none;
        font-size: 14px;
        opacity: .6;
    }
`