import { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { PopLoadingWrapper, PopLoading, PopLoadingContent, PopLoadingText } from './styles/Loading.styled';

export const RollingTextLoading = ({ text, show }: {
    text: String[],
    show: boolean
}) => {
    const [ isFadeOut, setIsFadeOut ] = useState(false);
    const rollingTimer = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
    const fadeTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const textIdx = useRef(0);

    const RollingTime = 6000;
    const FadeTime = 1000;

    useEffect(() => {
        if (show) {
            rollingTimer.current = setInterval(() => {
                setIsFadeOut(true);
                if (textIdx.current >= text.length - 1) {
                    setIsFadeOut(false);
                    if (rollingTimer.current) clearInterval(rollingTimer.current);
                    if (fadeTimer.current) clearTimeout(fadeTimer.current);
                    return;
                };
                const newIdx = textIdx.current >= text.length - 1 ? 0 : textIdx.current + 1;
                textIdx.current = newIdx;
                fadeTimer.current = setTimeout(() => {
                    setIsFadeOut(false);
                }, FadeTime);
            }, RollingTime);
        }
        else {
            clearAll();
        }

        return () => {
            if (!show) {
                clearAll();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const clearAll = () => {
        textIdx.current = 0;
        setIsFadeOut(false);
        if (rollingTimer.current) clearInterval(rollingTimer.current);
        if (fadeTimer.current) clearTimeout(fadeTimer.current);
    }

    if (!show) return null;
    return (
        <PopLoadingWrapper>
            <PopLoading>
                <Spinner animation="border" variant="light" />
                <PopLoadingContent>
                    <PopLoadingText className={`${isFadeOut ? 'fade' : ''}`}>
                        {text[textIdx.current]}
                    </PopLoadingText>
                </PopLoadingContent>
            </PopLoading>
        </PopLoadingWrapper>
    )
}