import { API_URL, ACC_KEY } from '../config';

export const getConsentId = async (esiid, email, phone, zipcode, customer_name, utility_code) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("esiid", esiid);
    formdata.append("email", email);
    formdata.append("phone", phone);
    formdata.append("zipcode", zipcode);
    formdata.append("customer_name", customer_name);
    formdata.append("utility_code", utility_code);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v2/tdu_usage_retriever/submit`;

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getUsageViaTdu = async (consent_id, {...options}) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("consent_id", consent_id);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
        ...options
    };

    const url = `${API_URL}v2/tdu_usage_retriever/get`;

    const response = await fetch(url, requestOptions)
    return response.json()
}