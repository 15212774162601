import styled from 'styled-components';

interface ProcessBarItemProps {
    active?: boolean
}

export const StyledProcessBar = styled.div`
    display: flex;
    border-bottom: 1px solid #ddd;
`

export const ProcessBarItem = styled.div<ProcessBarItemProps>`
    flex: 1;
    text-align: center;
    padding: 16px 12px;
    line-height: 1.1;
    color: ${({ active }) => { return active ? '#000' : '#999' }};
    position: relative;
    font-weight: ${({ active }) => { return active ? '600' : '400' }};

    &::after{
        display: block;
        content: ' ';
        width: 100%;
        height: 2px;
        background-color: ${({ active, theme }) => { return active ? theme.colors.primary : 'transparent' }};
        position: absolute;
        bottom: -1px;
        left: 0;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`