import styled from 'styled-components';

interface RowProps {
    reverse?: boolean;
}

export const SectionRow = styled.div<RowProps>`
    display: flex;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    align-items: center;
    padding: 44px 0;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 24px 0;
    }
`

export const SectionContent = styled.div`
    padding: 0 44px;

    h2 {
        margin-bottom: 24px;
        font-size:30px;
        font-weight:700;
    }

    p {
        color: #666;
    }

    @media (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 24px;

        h2 {
            font-size: 18px;
        }
    }
`

export const SectionImg = styled.div`
    flex-shrink: 0;
    max-width: 45%;
    
    img {
        max-width: 100%;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
`

export const HomeSection = styled.div`
    background-color: #fff;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 32px;
`

export const PartnerRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0;
    justify-content: center;
    align-items: center;

    img {
        width: 124px;
        margin: 24px 20px;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .6;
    }
`

export const HomepageTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0 64px;

    @media (max-width: 767px) {
        flex-direction: column;
        padding: 20px 0;
    }
`

export const HomepageTopSectionMain = styled.div`
    width: 50%;

    h1{
    	font-family: 'ARIRegular', sans-serif;
        font-size: 40px;
        font-weight: 700;
        margin-bottom:16px;

        span{
            font-size: 50px;
            font-weight: 700;
            color: #E94657;
        }

        @media (max-width: 768px) {
            font-size: 32px;

            span{
                font-size: 40px;
            }
        }
    }

    p{
        font-size: 20px;
        font-weight: 400;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const HomepageTopSectionImg = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;

    img {
        width: 90%;
        max-width: 640px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`

export const HomeCardTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`

export const HomeCardDesc = styled.div`
    font-size: 16px;
`

export const StepCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    justify-content: center;

    img{
        height: 80px;
        @media (max-width: 768px) {
            height: 60px;
        }
    }

    p{
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
`

export const HomeTitileLine = styled.p`
    font-family: 'ARIRegular', sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding:50px 0;

    @media (max-width: 768px) {
        font-size: 26px;
        padding: 20px 0;
    }
`

export const HomeDescLine = styled.p`
    font-size: 25px;
    text-align: center;
    padding:50px 0;

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 30px 0;
    }
`