import { css } from "@emotion/css"
import { useState } from "react"
import { PlanProps } from "../../types"
import { LoadingRow } from "../loading-row"
import { MyButton, WCard } from "../styles/MyComponents.styled"
import { LowestPrice } from "./lowest-price"

export const LowestList = (props: {
    topPlans: Array<PlanProps>,
    handleClickEnroll: Function
}) => {

    let [focusIndex, setFocusIndex] = useState(0)
    let [bkColor, setBkColor] = useState('0,184,40')

    let { topPlans, handleClickEnroll } = props

    if (topPlans.length < 1) return <div style={{ margin: '0 auto' }}><LoadingRow /></div>

    return <>
        <div className={css`
            flex: 0 0 170px; 
            @media (max-width: 768px) {
                margin:0 53px;
            }
        `}>
            <WCard md variant='up' color={`rgb(${bkColor},.1)`} style={{ height: 238, gap: 10 }}>
                <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${topPlans[focusIndex].provider_id}`} alt="" width={70} />
                <p className='mb-0' style={{ color: `rgb(${bkColor})`, fontSize: '32px', fontWeight: 600 }}>{topPlans[focusIndex].rate}¢</p>
                <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600 }}>Fixed</p>
                <p className='mb-0' style={{ color: '#646464', fontSize: '12px', fontWeight: 400 }}>{`Total Est.Rate/kwh
                        $${(topPlans[focusIndex].total / parseInt(topPlans[focusIndex].contract_term)).toFixed(2)} Est.Avg. Monthly Cost`}</p>
                <p className='mb-0' style={{ color: `rgb(${bkColor})`, fontSize: '12px', fontWeight: 700 }}>Not Free Energy</p>
            </WCard>
            <WCard md variant='down' color={`rgb(${bkColor},.1)`} style={{ height: 77, gap: 10 }}>
                {/* <img src="images/shield.png" alt="" />
                <p className='mb-0' style={{ color: '#fff', fontSize: '12px', fontWeight: 700 }}>Protect your leg and arm</p> */}
                <MyButton onClick={() => handleClickEnroll(topPlans[0].plan_id)}>
                    <img src="/images/cart.png" alt="" />
                    <p className='mb-0' style={{ color: '#fff', fontSize: '12px', fontWeight: 700 }}>Buy the plan</p>
                </MyButton>
            </WCard>
        </div >
        <div style={{ flex: 1 }}>
            <p className={css`
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                margin: 5px 0 15px;

                @media (max-width: 768px) {
                    font-size: 14px;
                }
            `} >
                Lowest Price of Free Energy For Your Address
            </p>
            {topPlans.length >= 2 ? <LowestPrice setBkColor={setBkColor} data={topPlans} setFocusIndex={setFocusIndex} focusIndex={focusIndex} /> : <div style={{ margin: '0 auto' }}><LoadingRow /></div>}
        </div>
    </>

}