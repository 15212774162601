import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';
import type { ChooseDataSourceModalProps } from '../types';
import { LoadingRow } from './loading-row';

export const ChooseDataSourceModal: React.FC<ChooseDataSourceModalProps> = ({
    show,
    handleClose,
    handleRedirect,
    utility
}) => {
    const { t } = useTranslation('usage');
    const [ recommend, setRecommend ] = useState('');

    useEffect(() => {
        if (utility) {
            const isOncor = utility.utility_name.toLowerCase().indexOf('oncor') >= 0;
            setRecommend(isOncor ? 'smt' : 'tdu');
        }
    }, [utility])

    return (
        <Modal size="lg" show={show} onHide={() => {handleClose()}}>
            <Modal.Header closeButton>
                <Modal.Title>{t('View All Plans Now')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="get-usage-option highlight" onClick={() => { handleRedirect('plan') }}>
                    <div>
                        <h5>{t('Forecast My Usage')}</h5>
                        <p>{t('Estimate usage with your house square footage')}</p>
                    </div>
                </div>
                {
                    !recommend &&
                    <div className="pt-5 pb-5"><LoadingRow /></div>
                }
                {
                    recommend &&
                    <>
                        <div className="divider"><span>Or</span></div>
                        <p className="text-center">{t('To provide you with the most suitable plan, we need to know your usage patterns.')}</p>
                        <div id="get-usage-option-wrapper">
                            {
                                recommend === 'tdu' &&
                                <div className="get-usage-option recommend" onClick={() => { handleRedirect('tdu') }}>
                                    <div>
                                        <h5>{t('Retrieve My Actual Usage')}</h5>
                                        <p>{t('Quickest way to pull usage pattern from your utility account')}</p>
                                    </div>
                                </div>
                            }
                            <div className={`get-usage-option ${recommend === 'smt' ? 'recommend' : ''}`} onClick={() => { handleRedirect('myaccount') }}>
                                <div>
                                    <h5>{t('MyAccount')}</h5>
                                    <p>{t('Instant access of your usage history through MyAccount')}</p>
                                </div>
                            </div>
                            <div className="get-usage-option" onClick={() => { handleRedirect('smt') }}>
                                <div>
                                    <h5>{t('Use Smart Meter Texas (SMT) Data')}</h5>
                                    <p>{t('Instant access of your usage history through your smart meter number')}</p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}