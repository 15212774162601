import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const SavingCalculation = () => {
  return (
    <>
      <Header />
      <ContentPage>
        <Container>
          <h1>How is the 10.85% savings on the website calculated?</h1>
        </Container>
      </ContentPage>
    </>
  )
}
