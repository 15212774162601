import React from "react";
import { Modal, Button } from 'react-bootstrap';
import { GetUsage } from "../get_usage";

export const ChooseDataSourceModal: React.FC<any> = ({
    show,
    handleClose,
}) => {
    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Body>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="light"
                        onClick={handleClose}
                        style={{
                            border: "none",
                            fontSize: "1.25rem",
                            fontWeight: "bold",
                            cursor: "pointer",
                        }}
                    >
                        ✖
                    </Button>
                </div>
                <GetUsage />
            </Modal.Body>
        </Modal>
    );
};
