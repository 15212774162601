import styled from 'styled-components';

interface ButtonProps {
    variant?: string;
    md?: boolean;
    disabled?: boolean;
    bk?: string;
    color?: string;
}

export const ButtonGroup = styled.div`
    display: flex;
`

export const Button = styled.button<ButtonProps>`
    background: ${props => props.disabled ? (props.variant === 'secondary' ? '#fff' : '#fe4459') : (props.variant === 'secondary' ? '#fff' : '#fe4459')};
    color: ${props => props.variant === 'secondary' ? '#FE4459' : '#fff'};
    border:  ${props => props.variant ? '.5px solid #FE4459' : '0 none'};
    font-weight: 600;
    font-size: ${props => props.md ? '12px' : '16px'};
    padding: ${props => props.md ? '8px 11px' : '8px 30px'};
    border-radius: 50px;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    transition: opacity .3s;
    :hover{
        background:${props => props.variant === 'secondary' ? '#ffeced' : ''};
        box-shadow: ${props => props.variant === 'secondary' ? '' : '0 0 5px #fe4459'};
    }
`

interface TextProps {
    normalSize?: string;
    normalWeight?: number;
    wideSize?: string;
    wideColor?: string;
}

export const Text = styled.div<TextProps>`
    font-size:${props => props.normalSize ? props.normalSize : '12px'};
    font-weight:${props => props.normalWeight && props.normalWeight};
    @media (min-width: 768px) {
        font-size:${props => props.wideSize && props.wideSize};
        color:${props => props.wideColor && props.wideColor};
    }
`

interface CardProps {
    color?: string;
    variant?: string;
    sm?: boolean;
    border?: boolean;
    bColor?: string;
    md?: boolean;
}

export const Card = styled.div<CardProps>`
    padding: 30px;
    border-radius: 40px;
    position:relative;
    background-color:${props => props.color ? props.color : '#fff'};
    width: 100%;
    border: ${props => props.border ? `2px solid ${props.bColor ? props.bColor : '#eef0f2'}` : '0 none'};

    ${props => props.md && `
        display:flex;
        align-items:center;
        justify-content:center;
        gap:10px;
        flex-direction:column;
    `}
`

export const WCard = styled.div<CardProps>`
    padding: 18px;
    margin: 9px 0;
    margin-top: ${props => props.variant === 'down' && '0'};
    margin-bottom: ${props => props.variant === 'up' && '0'};
    border-radius: ${props => props.variant ? props.variant === 'down' ? '0 0 20px 20px' : '20px 20px 0 0' : '20px'};
    background:${props => props.color ? props.color : '#fff'};
    border:${props => props.variant ? "" : "1px #ccc solid"};
    width: 100%;
    height: 100%;
    
    ${prop => prop.md && `
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        padding:10px;
        text-align:center;
    `}
`

export const FontBlock = styled.div`
    display: flex;
    flex-direction: column;

    span{
        font-weight:600;
        font-size:12px;
        
        @media (min-width: 768px) {
            font-size:18px;
        }       
    }
`

export const FlexLine = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    gap:5px;
`

export const MyButton = styled.button<ButtonProps>`
    display:flex;
    justify-content: center;
    gap:5px;
    cursor:pointer;
    width:100%;
    color: ${props => props.color ? props.color : '#fff'};
    background:${props => props.bk ? props.bk : '#00b828'};
    border:  0 none;
    font-weight: 600;
    font-size: 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: opacity .3s;
    padding: 7px 0;

    :hover{
        opacity: .85;
    }
`