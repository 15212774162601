import { css } from '@emotion/css'
import { Container, Image } from 'react-bootstrap'
import Footer from './components/footer'
import Header from './components/header'
import {
    HomeCardDesc,
    HomeCardTitle,
    HomeDescLine,
    HomeTitileLine,
    HomepageTopSection,
    HomepageTopSectionImg,
    HomepageTopSectionMain,
    SectionContent,
    SectionImg,
    SectionRow,
    StepCard
} from './components/styles/Homepage.styled'
import { Button, Card } from './components/styles/MyComponents.styled'
import { ValidateAddress } from './validate_address'

export const Homepage = () => {

    const handleTry = () => {
        window.scrollTo(0, 0)
    }

    return (
        <>
            <Header />
            <div className={css`
                background: linear-gradient(180deg, rgba(233, 70, 87, 0.05) 0%, rgba(233, 70, 87, 5e-06) 100%);
            `}>
                <Container>
                    <HomepageTopSection>
                        <HomepageTopSectionMain>
                            <h1>Use AI to help you find the <span>TRAP</span> of free energy</h1>
                            <p style={{ marginBottom: 40 }}>The Electricity Companies selling a variety of Free Energy will only make you spend more money.</p>
                            <ValidateAddress />
                        </HomepageTopSectionMain>
                        <HomepageTopSectionImg>
                            <Image src="/images/home_img1.png" alt="" />
                        </HomepageTopSectionImg>
                    </HomepageTopSection>
                </Container>
            </div>

            <Container>
                <HomeTitileLine>Tell if a Free Energy Plan is Really Free</HomeTitileLine>
                <div className='d-flex justify-content-center'>
                    <img src="/images/home_img4.png" alt="" className={css`
                        width:81%;

                        @media(max-width:768px){
                            width:100%;
                        }
                    `} />
                </div>
                <HomeDescLine>Have you ever found yourself wondering if the Free energy plans that energy companies offer are truly free? Are you skeptical about whether or not you will end up with hidden fees or charges?</HomeDescLine>
            </Container>

            <Container className={css`
                padding:50px 5px;

                @media(max-width:768px){
                    padding:20px 1rem;
                }
            `}>
                <img src="/images/home_img5.png" alt=""
                    style={{ width: '100%' }}
                    className={css`
                    @media(max-width:768px){
                        display:none;
                    }
                `} />
                <div className={css`
                    @media(min-width:768px){
                        display:none;
                    }
                `}>
                    <div className={css`
                    display:flex;
                    gap:20px;
                    align-items:center;
                    margin-bottom:20px;
                `}>
                        <div style={{ fontSize: 30 }}>⚠️</div>
                        <HomeCardTitle>Free energy plans may result in financial losses</HomeCardTitle>
                    </div>
                    <img src="/images/home_img6.png" alt=""
                        style={{ width: '100%' }}
                    />
                </div>

                <HomeDescLine>Free electricity plans are being marketed across Texas, with catchy names such as Reliant Truly Free Weekends and TXU Free Nights & Solar Days. However, these plans often involve <span style={{ fontWeight: 700, color: '#FF0000' }}>higher rates</span> to cover the cost of "free" electricity. Therefore, most residential customers will not benefit from them.</HomeDescLine>
            </Container>

            <div className={css`
                background: linear-gradient(180deg, rgba(233, 70, 87, 0.05) 0%, rgba(233, 70, 87, 5e-06) 100%);
            `}>
                <Container>
                    <HomeTitileLine>FreeEnergy.LOL
                        <span className={css`
                            margin-left: 10px;
                            @media(max-width:768px){
                                display:block;
                                margin-left:0;
                                font-size:24px;
                            }
                        `}>
                            can helps you
                        </span>
                    </HomeTitileLine>

                    <Card md>
                        <SectionRow reverse>
                            <SectionContent>
                                <h2>Say goodbye to energy traps with AI</h2>
                                <p>Tired of being misled by "Free Energy" providers? Turn to AI to help you uncover hidden costs and save money. </p>
                            </SectionContent>
                            <SectionImg>
                                <img src="/images/home_img2.png" alt="" />
                            </SectionImg>
                        </SectionRow>
                    </Card>

                    <div className={css`
                        display:flex;
                        padding:50px 0;
                        gap:50px;
                        @media(max-width:768px){
                            flex-direction:column;
                            padding:20px 0;
                            gap:20px;
                        }
                    `}>
                        <Card>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <img src="/images/icon_home_title1.png" alt="" width={30} />
                                <HomeCardTitle>Automated AI Electricity Plan Comparison</HomeCardTitle>
                            </div>
                            <HomeCardDesc>Uses Artificial Intelligence to constantly scan the market for the lowest cost electricity plans run against your smart meter data to calculate the lowest cost electricity plan for your home. There’s no obligation or monthly fee, it’s totally free and unbiased.</HomeCardDesc>
                        </Card>
                        <Card>
                            <div className='d-flex gap-3 align-items-center mb-2'>
                                <img src="/images/icon_home_title2.png" alt="" width={30} />
                                <HomeCardTitle>Say goodbye to energy traps with AI</HomeCardTitle>
                            </div>
                            <HomeCardDesc>First up, I need your address. Don’t worry, I respect your piracy and apricate your trust in my platform. I will safe guard your data and only use it to benefit you i.e. I need your full address to look up some basic data so we can start your flat bill personalization.</HomeCardDesc>
                        </Card>
                    </div>

                    <Card>
                        <p className='text-center'>
                            <span className={css`
                                font-size: 76px;
                                font-weight: 700;
                                color:#e94657;
                                margin-right: 10px;
                                
                                @media(max-width:768px){
                                    font-size: 40px;
                                }
                            `}>3</span>
                            <span className={css`
                            	font-family: 'ARIRegular', sans-serif;
                                font-size: 32px;
                                margin-right: 10px;

                                @media(max-width:768px){
                                    font-size: 32px;
                                }
                            `}>Easy steps</span>
                            <span className={css`
                                font-size: 22px;

                                @media(max-width:768px){
                                    font-size: 18px;
                                }
                            `}>Avoiding the Free Energy Trap</span>
                        </p>

                        <div className={css`
                            display:flex;
                            gap:20px;
                            justify-content: space-around;
                            align-items: center;

                            .step_arrow{
                                height:43px;
                                @media (max-width: 768px) {
                                    display:none;
                                }
                            }

                            @media (max-width: 768px) {
                                gap:30px;
                            }
                        `}>
                            <StepCard>
                                <img src="/images/icon_step1.png" alt="" />
                                <p>Enter Your Address</p>
                            </StepCard>
                            <img className='step_arrow' src="/images/step_arrow1.png" alt="" />
                            <StepCard>
                                <img src="/images/icon_step2.png" alt="" />
                                <p>See Our Recommendations</p>
                            </StepCard>
                            <img className='step_arrow' src="/images/step_arrow2.png" alt="" />
                            <StepCard>
                                <img src="/images/icon_step3.png" alt="" />
                                <p>Switch and save</p>
                            </StepCard>
                        </div>

                        <div className='d-flex justify-content-center mb-3 mt-3'>
                            <Button onClick={handleTry} >Try Now</Button>
                        </div>
                    </Card>

                </Container>
            </div>

            <Footer />
        </>
    )
}
