import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import { PUCT_number } from '../common/data';
import { JumpButton } from '../components/plan/JumpButton';
import { Button } from '../components/styles/MyComponents.styled';
import type { PlanRowProps } from '../types';
import { MonthlyBreakdownTable } from './monthly-breakdown-table';

export const PlanRow: React.FC<PlanRowProps> = ({
    val,
    usage,
    hidePrice,
    handleShowAccessSmtSection,
    handleCompare,
    compare,
    handleClickEnroll,
    avgPrice,
    handleShowSidebySideModal,
    jumpIcons = 1
}) => {
    const { t } = useTranslation('plan');
    const {
        plan_name,
        contract_term,
        base_rate,
        total,
        total_usage,
        rate,
        renewable,
        cancel_fee,
        cancel_fee_type,
        plan_desc,
        terms_url,
        efl_url,
        provider_id,
        month,
        is_prepaid,
        plan_id,
        yrac,
        type,
    } = val;

    const [showDetail, setShowDetail] = useState(false);

    const is100Green = parseInt(renewable) === 100;

    let saving = 0;
    if (avgPrice !== '0') {
        saving = (parseFloat(avgPrice) - rate / 100) / parseFloat(avgPrice);
    }

    saving = Math.round(saving * 10000) / 100;
    //TODO mark if hidden
    hidePrice = false

    return (
        <div className="plan-row-wrapper">
            <div className="plan-row">
                <div className="plan-content d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column flex-grow-1">
                        <div className="plan-overview mb-4">
                            <div className="rep-logo">
                                <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${provider_id}`} alt="" />
                                {
                                    PUCT_number[provider_id] &&
                                    <i>PUCT# {PUCT_number[provider_id]}</i>
                                }
                            </div>
                            <div className="plan-info-group">
                                <div className="plan-info plan-info-terms">
                                    <div>{contract_term} <span>{t('Months')}</span></div>
                                    <label>{t('Plan Length')}</label>
                                </div>
                                <div className="plan-info">
                                    <div>{(parseFloat(base_rate) * 100).toFixed(2)}¢</div>
                                    <label>{t('Base Rate')}</label>
                                </div>
                                <div className="plan-info">
                                    <div>{hidePrice ? 'N/A' : <NumberFormat value={(total / parseInt(contract_term)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix="$" />}</div>
                                    <label>{t('Est. Avg. Monthly Cost')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="plan-intro">
                            <div className="plan-name">{plan_name}</div>
                            <div className="plan-tags d-flex align-items-center flex-wrap mt-2 mb-3">
                                {is100Green && <span>{t('100% Green')}</span>}
                                {is_prepaid === '1' && <span>{t('Prepaid')}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <BootstrapButton
                            variant="light"
                            size="sm"
                            onClick={() => {
                                setShowDetail(!showDetail);
                            }}
                            style={{ border: '1px solid #ddd', backgroundColor: '#fff' }}
                        >
                            <span className="me-2">{t(`${showDetail ? 'Hide' : 'Show'} Detail`)}</span>
                            <FontAwesomeIcon icon={showDetail ? "caret-up" : "caret-down"} />
                        </BootstrapButton>
                    </div>
                </div>
                <div className="plan-options d-flex flex-column justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="plan-info">
                            <div className="plan-est-rate d-flex align-items-center justify-content-center">
                                {hidePrice ? 'N/A' : `${rate}¢`}
                                {/* {
                                    !hidePrice &&
                                    <div className="ms-2" style={{ fontSize: 0 }}>
                                        <i data-tip={t("The Total Est. Rate has factored in your usage pattern, tiered energy rates, TDU delivery fee, basic service charge, bill credit/rebate and other recurring charges if applicable")} className="tooltip-mark">?</i>
                                        <ReactTooltip className="tooltip" effect="solid" />
                                    </div>
                                } */}
                            </div>
                            <label>{t('Total Est. Rate/kWh')}</label>
                        </div>
                        {/* {
                            saving > 0 &&
                            <div className="plan-saving">{t('Saving')}: {saving}%</div>
                        } */}
                        {/* <div className="text-center mt-2" style={{ fontSize: 13, color: '#999' }}>{t('Cancellation Fee')}: {cancel_fee}{cancel_fee_type === 'PerMonth' ? ` (${t('Per Remaining Month')})` : ''}</div> */}
                        <div className="text-center mt-2" style={{ fontSize: 13, color: '#646464', padding: '0 30px' }}>per kWh (Fri 8:00 PM until Mon 5:59 AM)</div>
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                        {
                            !hidePrice &&
                            <JumpButton index={jumpIcons}
                                handleShowSidebySideModal={handleShowSidebySideModal}
                                val={val}
                            />
                        }
                        {
                            hidePrice &&
                            <Button md onClick={() => handleShowAccessSmtSection()}>{t('Get Rate')}</Button>
                        }
                    </div>
                </div>
            </div>
            <div className={`plan-detail ${showDetail ? 'active' : ''}`}>
                <div className="pt-4 pb-4">
                    <div className="plan-detail-heading">{t('Estimated Annualized Cost')}</div>
                    {
                        !hidePrice &&
                        <div className="mb-2">
                            {t('Total Est. Cost')}: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix="$" /> ({t('Based on')} <NumberFormat value={total_usage} displayType={'text'} suffix={'kWh'} thousandSeparator={true} />)
                        </div>
                    }
                    <MonthlyBreakdownTable usage={usage} monthlyPrice={month} hidePrice={hidePrice} />
                    <div className="plan-detail-heading">{t('Plan Details')}</div>
                    <div>{plan_desc}</div>
                    <div className="mt-4">
                        <div>{t('Renewable Percentage')}: {renewable}%</div>
                    </div>
                    <div className="plan-links mt-3">
                        {
                            terms_url && <a href={terms_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Terms of Service')}</span></a>
                        }
                        {
                            efl_url && <a href={efl_url} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">{t('Facts Label')}</span></a>
                        }
                        {
                            yrac && <a href={yrac} target="_blank" rel="noreferrer"><FontAwesomeIcon icon="file-pdf" /><span className="ms-2">YRAC</span></a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}