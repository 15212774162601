import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import useWindowSize from '../../common/hooks/useWindowSize';
import { LoadingRow } from '../loading-row';

const color = ['0, 184, 40', '86, 154, 0', '108, 159, 0', '225, 163, 2', '224, 108, 0', '234, 42, 0']
const barSize = { wide: { min: 25, max: 55 }, small: { min: 15, max: 30 } }

export const LowestPrice = (props: any) => {

    let { setBkColor, data, setFocusIndex, focusIndex } = props

    let [size, setSize] = useState(barSize.wide)
    let [dataSize, setDataSize] = useState(data.length)

    let { width } = useWindowSize()

    useEffect(() => {
        if (width < 500) {
            setSize(barSize.small)
        } else {
            setSize(barSize.wide)
        }
    }, [width])

    useEffect(() => {
        if (data.length !== dataSize) {
            setDataSize(data.length)
        }
    }, [data])

    if (data.length < 1) {
        return <LoadingRow />
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data.map((item: any, index: number) => {
                if (index > 5) {
                    return null;
                }
                return <div key={index} className={css`
                    // cursor:pointer;
                    display:flex;
                    gap:10px;
                    ${focusIndex === index && `background:rgba(${color[index]},.1);`}
                    padding:7px 0;
                `} onClick={() => {
                        // setFocusIndex(index)
                        // setBkColor(color[index])
                    }}>
                    <div className={css`
                        flex:0 0 108px;
                        font-weight:600;
                        font-size:12px;
                        width:100%;
                        text-align:right;
                        vertical-align:middle;
                        line-height:26px;
                    `}>{item.provider_name}</div>
                    <div className={css`
                        background:rgba(${color[index]});
                        height:26px;
                        border-radius:0 40px 40px 0;
                        width:${index === 0 ? size.min : index === dataSize - 1 ? size.max : size.min + (data[index].rate - data[0].rate) / (data[dataSize - 1].rate - data[0].rate) * size.min}%;
                        position:relative;
                        &:before{
                            position:absolute;
                            content: '${item.rate}¢';
                            right:-15px;
                            width:10px;
                            color:#646464;
                            font-size:12px;
                            font-weight:600;
                            top:3px;
                        }
                      ${index !== 0 && `&:after{
                            position:absolute;
                            content: '${((item.rate - data[0].rate) / data[0].rate * 100).toFixed(0)}%';
                            right:-55px;
                            width:10px;
                            background-image:url(images/arrow_up.png);
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center;
                            padding-left: 10px;
                            color:#EA2A00;
                            font-size:12px;
                            font-weight:600;
                            top:3px;
                        }`}
                    `} />
                </div>
            })}
        </div>
    )
}
