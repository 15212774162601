import { Row, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'
import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'

const TermsOfService = () => (
  <>
    <Header />
    <ContentPage>
      <Container>
        <Row className="py-5">
          <h2 className="fw-bold mb-4">
            Terms of Service
          </h2>

          <p className="mb-5">
            This Terms of Service Agreement (“TOS” or “Terms”) is a legal agreement between you and FreeEnergy.lol, “we”, or “us”), and governs how you may use our website (“Site”) and the FreeEnergy.lol Service defined in Section 1. When you utilize our Service, you are confirming your understanding of and agreement with these Terms and the license granted herein. By visiting or using the Site in any way, you agree to accept notices of any kind from FreeEnergy.lol in electronic form. Notices from FreeEnergy.lol intended for your receipt shall be deemed delivered and effective when sent by email to any email address you provide to us. Any rights not expressly granted herein are reserved by FreeEnergy.lol.
          </p>
          <p className="mb-5">
            You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in this Terms Policy, and to abide by and comply with this Terms Policy. In any case, you affirm that you are over the age of 18, as the service provided by the Site is not intended for children under 18. If you are under 18 years of age, then please do not use the Site.
          </p>
          <p className="mb-5">
            For all questions about how we use the information we obtain through your use of the Site and Services, please review our{" "}
            <Link to="/privacy_policy">Privacy Policy</Link>. By using the Site and Service, you agree to also be bound to the{" "}
            <Link to="/privacy_policy">Privacy Policy</Link>.
          </p>
          <h5 className="my-5">
            1. Service Description
          </h5>
          <p className="mb-5">
            FreeEnergy.lol is a Home Energy Management platform (the“Service”) that enables users to compare and contrast the services (“Energy Plans”) and associated rates offered by various third-party Competitive Energy Providers (“CEP”), and to enroll with a selected CEP. In addition, the Service offers information and guidance to assist users in selecting CEPs. Any questions, complaints or claims related to any service should be directed to the appropriate CEP.
          </p>
          <p className="mb-5">
            Should you choose to enroll with a CEP about which you learn from the Site, your contractual relationship will be with that CEP and not with FreeEnergy.lol. Your interaction with any CEP accessed through the Site is at your own risk and FreeEnergy.lol does not have any responsibility for the quality of the services you receive from any such CEP.
          </p>
          <p className="mb-5">
            Each user agrees and understands that he or she is appointing FreeEnergy.lol as his or her limited agent for purposes of accessing the user’s CEP or Smart Meter Texas (“SMT”) accounts and usage data. Therefore, each Member authorizes FreeEnergy.lol to request and/or retrieve information related to your electricity usage. FreeEnergy.lol may establish accounts, on your behalf, in order to retrieve this information when necessary. These accounts may be established with your current CEP or with SMT. If established, your credentials will be made available to you at your request.
          </p>
          <p className="mb-5">
            Each user agrees and understands that he or she is appointing FreeEnergy.lol as his or her limited agent for purposes of selecting and enrolling the user’s service location for electricity service with CEPs from time to time on their behalf.
          </p>
          <p className="mb-5">
            Each user agrees and understands that FreeEnergy.lol may receive compensation from a CEP for facilitating the enrollment of potential customers.
          </p>
          <h5 className="my-5">
            2. Establishing a User Account
          </h5>
          <p className="mb-5">
            If you sign up for a user account on the Site, you must select a username and password or use select social media credentials and provide accurate, complete, and updated contact information, identification details, and any other form of authentication that FreeEnergy.lol may request in its sole discretion. You are solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may terminate your account at any time by
            {" "}<Link to="/contact">Contact Us</Link>. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel accounts for any reason, in our sole discretion. These Terms will remain in effect after your account is terminated.
          </p>
          <h5 className="my-5">
            3. License
          </h5>
          <p className="mb-5">
            FreeEnergy.lol hereby grants you a non-exclusive, non-transferable, revocable, limited license to access and use the Site under the terms set forth below. The Site and the content, including, but not limited to, text, data, reports, opinions, software (including HTML-based computer programs), images, photos, graphics, graphs, charts, animations and video (collectively the “Content”) displayed on the Site may be used only for your personal and non-commercial use. Except as otherwise permitted under these Terms of Service, you agree not to copy, reproduce, modify, create derivative works from, or store any Content, in whole or in part, from the Site or to display, perform, publish, distribute, transmit, broadcast or circulate any Content to anyone, or for any commercial purpose, without the express prior written consent of FreeEnergy.lol.
          </p>
          <p className="mb-5">
            The trademarks, service marks, and logos (collectively, “Trademarks”) used and displayed on this website are registered or unregistered marks of FreeEnergy.lol and others. FreeEnergy.lol’s Trademarks may not be used in any way, including in advertising or publicity pertaining to distribution of materials on this website, without our prior, written permission. To request permission to use the Marks or Content on the Site, or any other FreeEnergy.lol material, please{" "}
            {" "}<Link to="/contact">Contact Us</Link>.
          </p>
          <h5 className="my-5">
            4. Termination of License
          </h5>
          <p className="mb-5">
            FreeEnergy.lol reserves the right to terminate the License granted herein and deny you access to the Service or any portion of it in its sole discretion, without any advance notice or liability to you, for any reason. FreeEnergy.lol also reserves the right to investigate suspected violations of these Terms or refer to law enforcement authorities any behavior that we believe may violate these Terms or applicable law. If your License is terminated for any reason, we reserve the rights in our sole discretion to: (i) remove your profile from the Site; and (ii) remove and discard any of your submitted Content. The termination of your License and account will not affect any additional right or relief to which FreeEnergy.lol may be entitled, at law or in equity. Upon termination of these Terms, all rights granted to you will automatically terminate and immediately revert to FreeEnergy.lol.
          </p>
          <h5 className="my-5">
            5. General Prohibitions
          </h5>
          <p className="mb-5">
            You may not do any of the following:
          </p>
          <p className="mb-5">
            (a) Copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code of the website or Service;
            <br />
            (b) Remove or obscure the copyright notice or other notices displayed in connection with the content accessible through the website or Service;
            <br />
            (c) Interfere with or disrupt the website, or servers and networks connected to the website, or circumvent, disable or otherwise interfere with security-related features of the Service or features that prevent or restrict use or copying of any content or that enforce limitations on use of the Service;
            <br />
            (d) Impersonate any other person or entity, or allow any third-party to use the Service under your account, share your password or other account information with anyone other than those you expressly authorize, or use the account of anyone other than your own;
            <br />
            (e) Modify the website or Service in any manner or form, or use modified versions thereof;
            <br />
            (f) Sell, assign, sublicense, or otherwise transfer any right in the Service or content accessible through the website;
            <br />
            (g) Use any robot, spider, scraper, or other automated means to access the Service for any purpose;
            <br />
            (h) Take any action that imposes or may impose an unreasonable or disproportionately large load on the FreeEnergy.lol infrastructure; or
            <br />
            (i) Knowingly disseminate or transmit any worms, viruses or other harmful, disruptive or destructive files, code, programs or other similar technologies, or otherwise utilize the Service in any manner that violates any statute, rule, or regulation.
          </p>
          <h5 className="my-5">
            6. Linking Policies
          </h5>
          <p className="mb-5">
            (a) Inbound Links: FreeEnergy.lol grants you the revocable permission to link to publicly available portions of the Site; provided, however, that any site which incorporates a link to this Site must not:
            (i) create a browser or border environment around any of the content on the Site or otherwise mirror any part of the Site;
            (ii) imply that FreeEnergy.lol is endorsing or sponsoring the site or any products or services offered by or through such site;
            (iii) present false information about, disparage, or otherwise harm FreeEnergy.lol or any other company, organization, service or product described on the FreeEnergy.lol website or Service;
            (iv) use any trademark of FreeEnergy.lol or of any other organization without the prior written permission from the trademark’s owner; or (v) contain content that FreeEnergy.lol in its sole opinion deems offensive or otherwise objectionable. In addition, the site must be owned and controlled by you or the person or entity placing the link, or otherwise permit you to enable such link subject to these Terms. By linking to the Site, you agree always to comply with these Terms, to be liable to us for any damages (statutory or otherwise) relating to your violation of these Terms, and to indemnify us as provided in section 10.
            <br />
            (b) Outbound Links: This site may include outbound links to websites that are not owned or operated by FreeEnergy.lol and are subject to different terms and conditions. If you follow outbound links, you will be legally bound by the terms and conditions of those other websites. Any outbound links are provided solely for your reference, and do not endorsement or indicate an affiliation with any other organization. FreeEnergy.lol makes no representations whatsoever concerning those other organizations, or any products or services they may offer.
          </p>
          <h5 className="my-5">
            7. No Warranty
          </h5>
          <p className="mb-5">
            THE CONTENT AND THE SITE ARE PROVIDED “AS IS” WITHOUT ANY WARRANTIES OF ANY KIND. FreeEnergy.lol MAKES NO GUARANTEES OR WARRANTIES AS TO THE ACCURACY, COMPLETENESS, TIMELINESS OR CURRENTNESS OF THE SITE, THE SITE’S CONTENT, OR ANY MATERIAL THAT CAN BE ACCESSED (VIA A DIRECT OR INDIRECT HYPERLINK OR OTHERWISE) THROUGH THE SITE. FreeEnergy.lol HEREBY DISCLAIMS ANY AND ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
            <p className="mb-5">
              OUR ESTIMATES OF AND COMPARISONS BETWEEN CEP PLANS INCLUDE ALL THE RECURRING CHARGES THAT YOU WILL SEE ON YOUR CEP BILL EXCLUDING GOVERNMENT FEES AND TAXES, BUT WE CANNOT AND DO NOT GUARANTEE THAT OUR ESTIMATES OR COMPARISONS ARE ACCURATE OR THAT THE SERVICES OFFERED BY CEPS ON OUR SERVICE ARE THE BEST OR LOWEST-PRICED SERVICES AVAILABLE TO YOU. THE CEPS APPEARING ON OUR SERVICE ARE INDEPENDENT THIRD PARTIES EXCEPT TO THE EXTENT EXPRESSLY DESCRIBED IN THESE TERMS.
            </p>
            <p className="mb-5">
              YOUR ACTUAL PRICES FOR THE SELECTED PLANS WILL BE DETERMINED BY YOUR INDIVIDUAL USAGE, INFORMATION FOUND IN THE “ELECTRICITY FACTS LABEL” MANDATED BY THE PUBLIC UTILITY COMMISSION OF TEXAS AND CEPS’ CUSTOMER AGREEMENTS, AND OTHER FACTORS OUTSIDE OUR CONTROL.
            </p>
            <p className="mb-5">
              THE DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT PERMITTED UNDER LOCAL LAW.
            </p>
          </p>
          <h5 className="my-5">
            8. LIMITATION OF LIABILITY & RELEASE.
          </h5>
          <p className="mb-5">
            In no event shall FreeEnergy.lol be liable to you or any other party for any indirect, special, incidental, consequential, or punitive damages, however and wherever arising, that may result from your use of the Site or the FreeEnergy.lol Service, including without limitation to losses incurred due to: (a) any inaccurate information published on the site or any site accessible via an outbound link; (b) software glitches, server failures, power outages, or any other issue beyond FreeEnergy.lol’s control; (c) any delays in or failure of the Service to operate as described; (d) any unauthorized disclosure of account information that may occur through the actions of any third-party, such as hackers; (e) any damage to your website code or content resulting from your use of our API; (f) damages or losses of any kind resulting from actions you take in reliance upon any results or other information provided by the Service; and (g) any other damages or losses you may incur in connection with your use of the FreeEnergy.lol site or service. IN NO EVENT WILL FreeEnergy.lol’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OR ACTION RELATING TO THE SITE AND/OR THE SERVICE EXCEED TEN UNITED STATES DOLLARS ($10.00) OR THE MINIMUM LIABILITY ALLOWED BY RELEVANT LAW, WHICHEVER IS GREATER.
          </p>
          <p className="mb-5">
            FreeEnergy.lol is not responsible for examining or evaluating the business practices, action, omissions, or services of participating Suppliers. If you elect to establish an account with a Supplier on this site, you agree that you will first carefully review the terms and conditions of that Supplier’s service. IN THE EVENT OF A DISPUTE BETWEEN YOU AND A SUPPLIER LISTED ON THE SITE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU RELEASE FreeEnergy.lol, ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND LEGAL REPRESENTATIVES FROM AND AGAINST CLAIMS AND DAMAGES OF ANY KIND, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH A DISPUTE.
          </p>
          <p className="mb-5">
            If you are a California resident, by using the Site, you explicitly waive the rights granted to you under California Civil Code 1542, which states: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.” If you are a New Jersey resident, certain sections of this TOS, including Sections 5, 6, and 7 may not apply to you pursuant to the New Jersey Truth in Consumer Contract, Warranty, and Notice Act. Additional states may not allow this exclusion or limitation of liability, so the above limitation or exclusion may not apply to you.
          </p>
          <h5 className="my-5">
            9. Indemnification
          </h5>
          <p className="mb-5">
            BY USING THE SITE, YOU AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS FreeEnergy.lol AND ITS OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, AND SERVICE OR CONTENT PROVIDERS FROM AND AGAINST ANY AND ALL LOSSES, LIABILITIES, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE ATTORNEYS’ FEES AND COURT COSTS, ARISING OR RESULTING FROM YOUR USE OF THE SITE AND ANY VIOLATION OF THESE TERMS OF SERVICE. IF YOU CAUSE A TECHNICAL DISRUPTION OF THE SITE OR THE SYSTEMS TRANSMITTING THE SITE TO YOU OR OTHERS, YOU AGREE TO BE RESPONSIBLE FOR ANY AND ALL LOSSES, LIABILITIES, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE ATTORNEYS’ FEES AND COURT COSTS, ARISING OR RESULTING FROM THAT DISRUPTION.
          </p>
          <h5 className="my-5">
            10. Copyright
          </h5>
          <p className="mb-5">
            All information and content on this website, including logos, graphics, code, script, sound, images and text (“Website Materials”) and any intellectual property rights embodied therein are the property of FreeEnergy.lol or third-party providers of information that appears on the site. Unless otherwise stated herein, none of the Website Materials may be copied, reproduced, modified, distributed, sold, broadcast, stored or otherwise used without the express permission of FreeEnergy.lol or the original copyright holder. You may display and print Website Materials solely for your personal, non-commercial use, provided that you do not modify the Website Materials and that you retain all copyright and other proprietary notices contained in them.
            <p className="mb-5">
              FreeEnergy.lol or its suppliers may provide software for download on the website. Use of software provided on the website is protected by copyright law and governed by the terms of the end user license agreement that accompanies such software. You may not install or use any software that contains an end user license agreement unless you first agree to the terms of the end user license agreement.
            </p>
          </p>
          <h5 className="my-5">
            11. Choice of Law; Forum
          </h5>
          <p className="mb-5">
            This document shall be governed in all respects by the laws of the United States and the State of Texas. Any claim or dispute you may have against us, whether subject to mandatory arbitration or otherwise, must be brought in Houston, Texas. For any matters not subject to mandatory arbitration, you agree to submit to the personal jurisdiction of the courts located within Harris County, Texas for the purpose of litigating all such claims or disputes.
          </p>
          <p className="mb-5">
            Should you file a claim other than as specified here, we may recover reasonable attorneys’ fees and costs, provided that we have notified you in writing of the improperly filed claim and you have failed to withdraw the claim promptly.
          </p>
          <h5 className="my-5">
            12. Arbitration Agreement and Class Action Waiver
          </h5>
          <p className="mb-5">
            You agree to arbitrate all disputes and claims that arise out of or relate to your use of the Service, regardless of when those disputes or claims arise. Therefore, you agree that, by using the Service, YOU ARE WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION BASED ON OR INVOLVING CLAIMS BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC, OTHER USERS, OR PERSONS SIMILARLY SITUATED. Any arbitration proceeding brought under this section shall be conducted by an arbitration service provider chosen by FreeEnergy.lol applying Texas law. The arbitration may be conducted telephonically, with the costs to be split evenly between the parties.
          </p>
          <h5 className="my-5">
            13. Limitation of Actions
          </h5>
          <p className="mb-5">
            Any claim or cause of action arising out of your use of the Service must be filed within one year after such claim or cause of action arose or it shall forever be barred, notwithstanding any statute of limitations or other law to the contrary. Within this period, any failure by FreeEnergy.lol to enforce or exercise any provision of this TOS or any related right shall not constitute a waiver of that right or provision.
          </p>
          <h5 className="my-5">
            14. Modification and Notice of Changes
          </h5>
          <p className="mb-5">
            FreeEnergy.lol reserves the right to modify the information contained on this website or these Terms at any time without notice. Accessing this website after any changes to these Terms have been posted thereon will constitute your agreement to the revised Terms. Unless specifically stated otherwise, any new features, products or services added to the website shall be subject to these Terms.
          </p>
          <h5 className="my-5">
            15. Entire Agreement
          </h5>
          <p className="mb-5">
            This Terms of Service and any document incorporated by reference herein constitute the entire agreement between you and us and govern your use of the Service, superseding any prior agreements between us.
          </p>
          <h5 className="my-5">
            16. Severability
          </h5>
          <p className="mb-5">
            If any provision of these Terms of Service is found by a court or other binding authority to be invalid, the remaining provisions shall continue in full force and effect.
          </p>
          <h5 className="my-5">
            17. How to Contact Us:
          </h5>
          <p className="mb-5">
            If you have any questions or concerns regarding this TOS you may contact us by United States Postal Mail at the address below.
          </p>
          <p className="mb-5">
            Postal Mailing Address:
            <br />
            eIQdigital, Inc.
            <br />
            c/o FreeEnergy.lol
            <br />
            2700 Post Oak Blvd., 21<sup>st</sup> Floor
            <br />
            Houston, TX 77056
          </p>
        </Row>
      </Container>
    </ContentPage>
  </>
);

export default TermsOfService;
