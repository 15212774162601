import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { fetchAddress, fetchUtility } from './api/api';
import { ChooseDataSourceModal } from './components/choose-data-source-modal';
import { Button } from './components/styles/MyComponents.styled';
import type { Address, Utilities } from './types';
import { css } from '@emotion/css';

export const ValidateAddress = () => {
    const { t } = useTranslation('homepage');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [addressOptions, setAddressOptions] = useState<Address[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<Address[]>([]);
    const [showDataSourceModal, setShowDataSourceModal] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['consent_id']);
    const [loadingUtility, setLoadingUtility] = useState(false);
    const [utility, setUtility] = useState<Utilities[]>([]);
    const [selectedUtility, setSelectedUtility] = useState<Utilities | undefined>(undefined);

    useEffect(() => {
        if (selectedAddress.length === 0) return;
        setLoadingUtility(true);
        const { zipcode } = selectedAddress[0];
        fetchUtility(zipcode).then(res => {
            setLoadingUtility(false);
            if (res) {
                setUtility(res);
                setSelectedUtility(res[0]);
            }
        })
    }, [selectedAddress])

    const handleSearch = (val: string) => {
        setLoading(true);
        fetchAddress(val).then(data => {
            setAddressOptions(data);
            setLoading(false);
        })
    }

    const handleClick = () => {
        if (selectedAddress.length === 0) return;
        const address = selectedAddress[0];
        localStorage.setItem('saved_address', address.street);
        // setShowDataSourceModal(true);
        // redirectTo('smt');
        redirectTo('plan');
    }

    const redirectTo = (type: string) => {
        const address = selectedAddress[0];
        const { street, esiid, zipcode, city, state } = address;
        const utilityCode = selectedUtility ? selectedUtility.utility_code : '';
        if (type === 'plan') {
            if (cookies) {
                removeCookie('consent_id');
            }
            navigate(`/plans?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&utcode=${utilityCode}`);
        }
        else {
            navigate(`/get_usage?address=${encodeURIComponent(street)}&esiid=${esiid}&zipcode=${zipcode}&city=${city}&state=${state}&source=${type}&utcode=${utilityCode}`);
        }
    }

    const chooseUtility = () => {
        if (utility.length <= 1 || !selectedUtility) return null;
        return (
            <Dropdown className="utility-select">
                <Dropdown.Toggle variant="light">
                    {selectedUtility.utility_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        utility.map((val, idx) => (
                            <Dropdown.Item
                                eventKey={idx}
                                key={idx}
                                onClick={() => {
                                    setSelectedUtility(val);
                                }}
                                active={val.utility_id === selectedUtility.utility_id}
                            >{val.utility_name}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            <div className='d-flex' style={{ padding: '7px 0 15px' }}>
                <div id="autocomplete-wrapper">
                    <img src="/images/icon_location.png" alt="" width={18} style={{ marginLeft: 14 }} />
                    <AsyncTypeahead
                        filterBy={() => true}
                        id="autocomplete"
                        isLoading={loading}
                        labelKey="label"
                        minLength={1}
                        onSearch={handleSearch}
                        options={addressOptions}
                        placeholder={`${t('Address')}...`}
                        size="lg"
                        onChange={(val: any) => setSelectedAddress(val)}
                        selected={selectedAddress}
                    />
                    {chooseUtility()}
                </div>
                <Button
                    onClick={handleClick}
                    disabled={!selectedAddress[0] || loadingUtility}
                    style={{ zIndex: 2 }}
                >
                    <span>{t('Continue')}</span>
                </Button>
            </div>

            <ChooseDataSourceModal
                show={showDataSourceModal}
                handleClose={() => { setShowDataSourceModal(false); }}
                handleRedirect={redirectTo}
                utility={selectedUtility}
            />
        </>
    )
}