import { css } from '@emotion/css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className={css`
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        `}>
            <Container>
                <div className={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;                    
                    a {
                        text-decoration: none;
                    }
                    span {
                        margin-left: 4px;
                        font-size: 12px;
                        color: #999;
                    }

                    @media (max-width: 768px) {
                        justify-content: center;
                    }
                `}>
                    <Link to="/" className={css`
                        padding:5px 0;
                        img{
                            height: 40px;
                        }
                    `}>
                        <img src="/images/logo_wide.png" alt="" />
                        <span
                            style={{
                                display: 'inline-block',
                                backgroundColor: 'rgb(254, 68, 89)',
                                color: 'white',
                                fontSize: '10px',
                                padding: '1px 4px',
                                borderRadius: '4px',
                                position: 'relative',
                                top: '-10px',
                                fontWeight: 'normal',
                            }}
                        >
                            BETA
                        </span>
                    </Link>
                </div>
            </Container>
        </div>
    )
}

export default Header