import styled from 'styled-components';

export const Mybox = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
	width:100%;
	padding: 0 20px;

    img{
        display:none;
		margin-bottom:2px;
		float:left;
    }

    .active{
        display:block;
		animation: shake-vertical 0.8s ease-out 0s 2 normal none;
    }

    button{
        cursor:pointer;
		width:100%;
		color: #fff;
		background:#fe4459;
		border:  0 none;
		font-weight: 600;
		font-size: 16px;
		border-radius: 20px;
		cursor: pointer;
		transition: opacity .3s;
		padding: 5px 0;

		:hover{
			opacity: .85;
		}

		@media (max-width: 768px) {
			font-size: 12px;
		}

    }

	@keyframes shake-vertical {
			
		0% {
			transform:translateY(0);
		}
		10% {
			transform:translateY(-8px);
		}
		20% {
			transform:translateY(8px);
		}
		30% {
			transform:translateY(-8px);
		}
		40% {
			transform:translateY(8px);
		}
		50% {
			transform:translateY(-8px);
		}
		60% {
			transform:translateY(8px);
		}
		70% {
			transform:translateY(-8px);
		}
		80% {
			transform:translateY(6.4px);
		}
		90% {
			transform:translateY(-6.4px);
		}
		100% {
			transform:translateY(0);
		}
	}

`