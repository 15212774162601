import { useState } from "react";
import { Mybox } from "../styles/JumpButton.styled";

export const JumpButton = (props: {
    index: number,
    handleShowSidebySideModal: Function,
    val: any
}) => {
    let [jump, setJump] = useState(false)
    let { index = 1, handleShowSidebySideModal, val } = props

    const JumpImg = (props: { index: number }) => {
        let { index } = props
        return <img key={index}
            className={jump ? 'active' : ''}
            src={`images/laugh${index % 2 + 1}.png`}
            alt=""
            width={Math.floor(Math.random() * 10) + 20} />
    }

    return <Mybox>
        {index > 5 && <div>
            {Array(index > 7 ? 3 : index - 5).fill('').map((_, i) => {
                return <JumpImg key={i} index={i} />
            })}
        </div>}
        <div>
            {Array(index > 5 ? 5 : index).fill('').map((_, i) => {
                return <JumpImg key={i} index={i} />
            })}
        </div>
        <button onClick={() => {
            if (jump) {
                handleShowSidebySideModal(val)
            } else {
                setJump(true)
            }
        }} >{jump ? 'Really ?' : 'Enroll Now'}</button>
    </Mybox>
}