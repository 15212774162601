import Header from '../components/header'
import { ContentPage } from '../components/styles/Page.styled'
import { Container } from 'react-bootstrap'

export const ShoppingTips = () => {
    return (
        <>
            <Header />
            <ContentPage>
                <Container>
                    <h1>Shopping Tips</h1>
                </Container>
            </ContentPage>
        </>
    )
}
