import {  ACC_KEY, API_URL } from '../config'
export const saveEnrollment = async (enrollmentId, restFields, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    if (enrollmentId) formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (let i in restFields) {
        formdata.append(i, restFields[i])
    }
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/save`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentRecord = async (enrollmentId, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/retrieve`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const confirmEnrollment = async (enrollmentId, specialPro, sign) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('enrollment_id', enrollmentId)
    if (sign) formdata.append('sign', sign)
    for (const key in specialPro) {
        if (specialPro[key]) formdata.append(key, specialPro[key])
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/submit`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getEnrollmentInputs = async (providerId) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('provider_id', providerId)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/input`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getBlackoutDate = async (product, zipcode, utility_code) => {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

    const formdata = new FormData()
    formdata.append('product', product)
    formdata.append('zipcode', zipcode)
    formdata.append('utility_code', utility_code)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow',
    }

    const url = `${API_URL}v2/enrollment/blackout_date`

    const response = await fetch(url, requestOptions)
    return response.json()
}
