import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { Button as BootstrapButton, Modal } from 'react-bootstrap';
import useWindowSize from '../common/hooks/useWindowSize';
import type { PlanProps, SidebySideModalProps } from '../types';
import { MyButton, WCard } from "./styles/MyComponents.styled";


export const SidebySideModal: React.FC<SidebySideModalProps> = ({
    show, handleClose, thatPlan, zipcode, utilityCode,
    usages, planType, handleClickEnroll, terms
}) => {
    let [sellPlan, setSellPlan] = useState<PlanProps>();

    useEffect(() => {
        let tsPlan = localStorage.getItem('ts_plan');
        tsPlan && setSellPlan(JSON.parse(tsPlan));
    }, []);

    const getMonthylyCost = (plan: PlanProps) => {
        let { contract_term, total } = plan;
        let monthlyCost = (total / parseInt(contract_term)).toFixed(2);
        return parseInt(monthlyCost);
    }

    let { width } = useWindowSize()

    const PlanCard = (prop: { data: PlanProps, sell?: boolean }) => {
        let { data, sell = false } = prop;

        return <WCard md style={{ flex: 1 }}>
            <img src={`https://www.powerlego.com/ApiGateway/v2/logo/provider/${data.provider_id}`} className='mt-3' alt="" width={160} />
            <div className="d-flex gap-3 mt-3 mb-2" style={{ alignItems: 'center' }}>
                <p className="card-font-2 mb-0">{data.rate}¢</p>
                {sell && <p className="card-font-1 mb-0">Fixed</p>}
            </div>
            <p className="card-font-1 mb-0"> Total Est.Rate/kwh</p>
            <p className="card-font-1 mb-0">＄{getMonthylyCost(data)} Est.Avg. Monthly Cost</p>
            <p className="card-font-1 mb-3">{sell ? 'Not Free Energy' : 'per kWh (Fri 8:00 PM until Mon 5:59 AM)'}</p>
            <p className="card-font-2 mb-0">{sell ? 'Fixed Rate Plan' : 'Free Electricity Plan'}</p>
            <p className="card-font-2 mb-1">＄{data.total}</p>
            <p className="card-font-1 mb-5">{`Total cost for ${data.contract_term} months`}</p>
            <div className='mb-4' style={{ width: '100%', padding: '0 20px' }}>
                <MyButton
                    bk={sell ? '#FE4459' : '#d9d9d9'}
                    color={sell ? '#fff' : '#000'}
                    onClick={() => {
                        !sell && window.open(data.enroll_url, '_blank');
                        sell && handleClickEnroll(data.plan_id)
                    }}
                >{sell ? 'I’m smart, I want to choose' : 'I’m rich, I want to choose'}</MyButton>
            </div>
        </WCard>
    }

    if (!thatPlan || !sellPlan) {
        return null;
    }

    return (
        <Modal size="lg" show={show} onHide={() => handleClose()}>
            <Modal.Header style={{ padding: '20px 20px 0', marginBottom: -10 }}>
                <BootstrapButton
                    className="close-btn"
                    variant="link"
                    onClick={() => handleClose()}
                    style={{
                        fontSize: 24,
                        color: '#000',
                        cursor: 'pointer',
                        float: 'right',
                    }}>
                    <FontAwesomeIcon icon="times" />
                </BootstrapButton>
            </Modal.Header>
            <Modal.Body>
                <div className={css`
                    display: flex;
                    gap: 18px;

                    @media (max-width: 991px) {
                        flex-direction: column-reverse;
                    }
                `}>
                    <PlanCard data={thatPlan} />
                    <PlanCard data={sellPlan} sell />
                </div>
                <div className={css`
                    margin: 0 auto;
                    margin-top: 70px;
                    margin-bottom: 40px;
                    border: 2px solid #FE4459;
                    border-radius: 20px;
                    width: fit-content;
                    padding: 10px 100px;
                    text-align: center;
                    position: relative;

                    @media (max-width: 768px) {
                        padding: 10px 30px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: -35px;
                        left: -50px;
                        background-image: url('/images/laugh1.png');
                        width: 80px;
                        height: 120px;
                        display: block;
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: 768px) {
                            width: 50px;
                            top: -20px;
                            left: -20px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: -55px;
                        right: -90px;
                        background-image: url('/images/cry_money.png');
                        width: 200px;
                        height: 150px;
                        display: block;
                        background-repeat: no-repeat;
                        background-size: contain;

                        @media (max-width: 768px) {
                            right: -40px;
                            top: -10px;
                            width: 120px;
                        }
                    }
                `}>
                    <p className={css`
                        margin-bottom:0;
                        font-size: 24px;
                        font-weight: 700;

                        @media (max-width: 768px) {
                            font-size: 18px;
                        }
                    `}>{`Choose free,  $ ${(getMonthylyCost(thatPlan) - getMonthylyCost(sellPlan)) * 12} more expensive`}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

